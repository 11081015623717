<template>
  <div class="mall-container">
    <div class="swiper-container">
      <div class="my-swipe">
        <div
          class="good-card"
          v-for="(item,index) in collectList"
          :key="index"
          @click="goseriesDetails(item)"
        >
          <div
            class="row-center row-center-img"
            :style="'width: 100%;height: 94vw;background-color: #D0D0D0;'"
          >
            <div class="row-center-img-inline">
              <img
                style=""
                :src="item.image"
                alt=""
              >
            </div>
          </div>
          <div class="good-card-bg">
            <p class="good-name"> {{item.name}} </p>
            <div
              style="margin-top: 10px;"
              class="row-start"
            >
              <limit-item
                v-if="item.drawFlag != 0"
                limitTitle=""
                :limitContent="item.drawFlag == 1 ? '抽签购' :''"
              ></limit-item>
            </div>

            <div
              class="row-left"
              style="margin-top: 0.625rem;"
            >
              <div :style="'width: 1.875rem;height: 1.875rem;background-image: url('+ item.creatorAvatar +');background-repeat: no-repeat;background-attachment: scroll;background-position: 0% 0%;background-size: 100% auto;background-color: transparent;border-radius: 50%;'"></div>
              <p class="author-name">{{item.creatorName}}</p>
            </div>
            <!-- <img
              class="good-type"
              :src="mediaType[item.cateId] == 'img' ? require('../assets/icon/media/img.png') : mediaType[item.cateId] == 'audio' ?  require('../assets/icon/media/audio.png'): mediaType[item.cateId] == 'video' ?  require('../assets/icon/media/video.png') :  require('../assets/icon/media/3d.png')"
              alt=""
            > -->
            <!-- <p class="good-prise">
              <span style="font-size: 1.625rem;margin-right:0.625rem;">¥</span>
              <span style="font-size: 2.1875rem;">{{ String(item.price).split('.')[0].trim()}}.</span>
              <span
                v-if="String(item.price).split('.').length == 2"
                style="font-size: 1.625rem;"
              >{{String(item.price).split('.')[1]}}</span>
            </p> -->
            <div
              class="buy-now row-center good-detail-buy-bottom"
              @click="goseriesDetails(item)"
            >立即查看</div>
          </div>

          <!-- <div
            v-if="item.showFlag==1"
            class="order-sales-status row-center"
            style="background: #000000;border-radius: 16px;opacity: 0.73;"
          >
            <img
              class="index-icon"
              :src="require('../assets/icon/time_limit.png')"
            />
            <p class="index-time-tip">售卖中</p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '../api/index-client'
import config from '../config'
import LimitItem from '../components/LimitItem.vue'
export default {
  props: {

  },
  components: {
    LimitItem
  },
  data () {
    return {
      mediaType: config.mediaType,
      goodsParam: {
        page: 0,
        limit: 12,
      },
      collectList: [],
    }
  },
  created () {
    this.$store.commit('HIDE_APPLOADING')
    this.getcollectList()
  },
  methods: {
    //系列查看更多
    goseriesDetails (valule) {
      this.$router.push('/seriesCollectionDetails?id=' + valule.id)
    },
    // 获取系列藏品
    getcollectList () {
      api
        .get('collect/series/list?page=' + this.goodsParam.page + '&limit=' + this.goodsParam.limit)
        .then(result => {
          if (result.data.success) {
            result.data.data.map(i => {
              this.collectList.push(i)
            })
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.loading = false
          this.$store.commit("HIDE_APPLOADING")
        })
    },
  },
}
</script>
<style lang="less" scoped>
.el-menu-demo {
  display: flex;
  justify-content: start;
  .el-menu-demo-item {
    margin: 0 1.25rem 0 1.25rem;
  }
}
.order-img-name-img {
  display: flex;
  justify-content: center;
  align-items: center;
  &-inline {
    display: block;
    text-align: center;
    height: 100%;
    width: 100%;
    position: relative;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      bottom: 0;
      right: 0;
      transform: translate(-50%, -50%);
    }
  }
}
.mall-container {
  display: flex;
  flex-direction: column;
  background: #1c1c1c;
  margin-bottom: 3.125rem;
  margin-top: 3.125rem;
  height: calc(100vh - 6.25rem);
  overflow-y: auto;
  width: 100%;
  z-index: 19;
  .row-right {
    img {
      width: 1rem;
    }
  }
  .row-center-img {
    display: flex;
    justify-content: center;
    align-items: center;
    &-inline {
      display: block;
      text-align: center;
      width: 100%;
      height: 100%;
      position: relative;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        bottom: 0;
        right: 0;
        transform: translate(-50%, -50%);
      }
    }
  }
  .good-card {
    position: relative;
    width: 94%;
    margin: 0.75rem 3% 0 3%;
    border-radius: 0.5rem;
    overflow: hidden;
    .good-card-bg {
      height: auto;
      min-height: 6.5rem;
      background-image: url('../assets/icon/goods-bg.png');
      background-repeat: no-repeat;
      background-attachment: scroll;
      // background-position: 0% 0%;
      background-size: cover;
      background-color: transparent;
      border-radius: 0 0 0.5rem 0.5rem;
      padding: 0 1.25rem 0.625rem 1.25rem;
      position: relative;
      .good-name {
        width: calc(100% - 20px);
        font-size: 1.1875rem;
        font-family: lantingheiweight;
        font-weight: bold;
        color: #ffffff;
        line-height: 1.6875rem;
        padding-top: 0.5rem;
      }

      .good-type {
        position: absolute;
        top: 0.85rem;
        right: 1.3125rem;
        width: 1.25rem;
        height: auto;
      }
      .author-name {
        font-size: 0.75rem;
        font-weight: 500;
        color: #c6c3c2;
        line-height: 1.0625rem;
        margin-left: 0.5625rem;
      }

      .good-prise {
        position: absolute;
        bottom: 0.625rem;
        right: 1.25rem;
        font-family: SFUIDisplay-Bold, SFUIDisplay;
        font-weight: bold;
        color: #ffffff;
        line-height: 2.625rem;
      }
    }
    .limit-content {
      left: 0;
      margin-top: 0.5rem;
    }
  }
}
.order-item {
  border-radius: 8px;
  background: linear-gradient(
    360deg,
    rgba(250, 232, 220, 0) 0%,
    rgba(243, 205, 182, 0.2) 100%
  );
  width: 95%;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}
.order-content {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}
.date-type-card {
  height: 2.4375rem;
  .date-type-tip {
    font-size: 0.875rem;
    font-family: lantingheiweight;
    font-weight: 400;
    color: #b3b3b3;
    line-height: 2.1875rem;
    margin-left: 1.0625rem;
  }
}
.date-type {
  height: 2.6875rem;

  .date-type-tip {
    font-size: 1.025rem;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #ffffff;
    line-height: 2.625rem;
    margin-left: 1.0625rem;
  }
}
.date-img {
  background-image: url('../assets/icon/invitationFriends/background.png');
  background-repeat: repeat;
  display: flex;
  border-radius: 8px;
  margin-top: 5px;
}
.my-swipe {
  width: 100vw;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
  width: 100%;
  // margin: 0 4%;
  .good-card {
    margin: 0;
  }
}
.swiper-container {
  height: auto;
  overflow-y: auto;
  /* 上一张 */
  .swiper-slide-prev {
    margin-top: 5px;
    height: 100vw !important;
  }
  /* 下一张 */
  .swiper-slide-next {
    margin-top: 5px;
    height: 100vw !important;
  }

  /* 中间的图片 */
  .swiper-slide-active {
    height: auto !important;
  }
  .order-menu {
    top: 0;
    width: 90vw;
    margin-left: 5vw;
    position: inherit;
    background: transparent !important;
    .el-menu {
      background: transparent !important;
      background-color: transparent !important;
    }
    .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
    .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
    .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
      background: transparent !important;
      background-color: transparent !important;
    }
    .el-menu-item {
      font-size: 18px;
    }
    .el-menu-item1 {
      margin-right: 50px;
    }
    .el-menu-item2 {
      margin-left: 188px;
      font-size: 14px;
    }
  }
  .collection-list {
    width: 90vw;
    margin-left: 5vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
  }
}
.buy-now {
  height: 2.75rem;
  position: relative;
  width: 100%;
  margin: 1rem 1.3125rem 0.625rem 0;
  background-image: url('../assets/icon/confirm-button.png');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 0% 0%;
  background-color: transparent;
  font-size: 1rem;
  font-family: lantingheiweight;
  font-weight: normal;
  color: #ffffff;
  line-height: 1.375rem;
  letter-spacing: 2px;
  cursor: pointer;
  background-size: cover;
}
</style>